import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'serverType',
    standalone: false
})
export class ServerTypePipe implements PipeTransform {

    public transform(value: string | null, ...args: unknown[]): string {
        if (typeof value !== 'string') {
            return '';
        }
        return value.split('\\').join('-');
    }

}
