<div class="card-container">
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title>Newest Servers</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-nav-list>
                <mat-list-item *ngFor="let server of newestServers" [routerLink]="['/servers', server.id]"
                               class="pointer">
                    <mat-icon matListItemIcon>storage</mat-icon>
                    <span class="card-list-item-title" matListItemLine>{{ server.productType }}</span>
                    <p matListItemLine>
                        <span>{{ server?.provider | serverType | titlecase }}</span>
                        <br/>
                        <span>{{ server.createdAt | date:'YYYY-MM-dd' }}</span>
                    </p>
                </mat-list-item>
            </mat-nav-list>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title>Updated Servers</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-nav-list>
                <mat-list-item *ngFor="let server of updatedServers" [routerLink]="['/servers', server.id]">
                    <mat-icon matListItemIcon>storage</mat-icon>
                    <span class="card-list-item-title" matListItemLine>{{ server.productType }}</span>
                    <p matListItemLine>
                        <span>{{ server?.provider | serverType | titlecase }}</span>
                        <br/>
                        <span>{{ server.createdAt | date:'YYYY-MM-dd' }}</span>
                    </p>
                </mat-list-item>
            </mat-nav-list>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title>Contract-due Servers</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-nav-list>
                <mat-list-item *ngFor="let server of expiringServers" [routerLink]="['/servers', server.id]">
                    <mat-icon matListItemIcon>storage</mat-icon>
                    <span class="card-list-item-title" matListItemLine>{{ server.productType }}</span>
                    <p matListItemLine>
                        <span>{{ server?.provider | serverType | titlecase }}</span>
                        <br/>
                        <span>{{ server.createdAt | date:'YYYY-MM-dd' }}</span>
                    </p>
                </mat-list-item>
            </mat-nav-list>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title>Pending Servers</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-nav-list *ngIf="pendingServers.length">
                <mat-list-item *ngFor="let server of pendingServers" [routerLink]="['/servers', server.id]">
                    <mat-icon matListItemIcon>storage</mat-icon>
                    <span class="card-list-item-title" matListItemLine>{{ server.productType }}</span>
                    <p matListItemLine>
                        <span>{{ server?.provider | serverType | titlecase }}</span>
                        <br/>
                        <span>{{ server.createdAt | date:'YYYY-MM-dd' }}</span>
                    </p>
                </mat-list-item>
            </mat-nav-list>
            <mat-list *ngIf="!pendingServers.length">
                <mat-list-item>
                    <mat-icon matListItemIcon>done</mat-icon>
                    <span matListItemLine>No pending servers.</span>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
    </mat-card>
</div>
