import { Component } from '@angular/core';
import { Server } from '../../../models/server';
import { Datastore } from '../../../services/datastore';
import { JsonApiQueryData } from 'mpugach-angular2-jsonapi';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    standalone: false
})
export class MainComponent {
    public newestServers: Server[] = [];
    public updatedServers: Server[] = [];
    public expiringServers: Server[] = [];
    public pendingServers: Server[] = [];

    constructor(
        private readonly datastore: Datastore,
        private readonly authService: AuthService,
    ) {
        this.getNewestServers();
        this.getUpdatedServers();
        this.getExpiringServers();
        this.getPendingServers();
    }

    private getNewestServers() {
        this.datastore.findAll(
            Server,
            { sort: '-createdAt', page: { size: 5 }, },
            new HttpHeaders({ Authorization: `Bearer ${this.authService.getToken()}` })
        ).subscribe((result: JsonApiQueryData<Server>) => {
            this.newestServers = result.getModels();
        });
    }

    private getUpdatedServers() {
        this.datastore.findAll(
            Server,
            { sort: '-updatedAt', page: { size: 5 }, },
            new HttpHeaders({ Authorization: `Bearer ${this.authService.getToken()}` })
        ).subscribe((result: JsonApiQueryData<Server>) => {
            this.updatedServers = result.getModels();
        });
    }

    private getExpiringServers() {
        this.datastore.findAll(
            Server,
            { sort: 'contractExpireAt', page: { size: 5 }, },
            new HttpHeaders({ Authorization: `Bearer ${this.authService.getToken()}` })
        ).subscribe((result: JsonApiQueryData<Server>) => {
            this.expiringServers = result.getModels();
        });
    }

    private getPendingServers() {
        this.datastore.findAll(
            Server,
            { filter: { status: 'pending' }, page: { size: 5 }, },
            new HttpHeaders({ Authorization: `Bearer ${this.authService.getToken()}` })
        ).subscribe((result: JsonApiQueryData<Server>) => {
            this.pendingServers = result.getModels();
        });
    }
}
