import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Datastore } from '../../../services/datastore';
import { Server } from '../../../models/server';
import { User } from '../../../models/user';
import { AuthService } from '../../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import {
    ConfirmServerRestartDialogComponent
} from './components/confirm-server-restart-dialog/confirm-server-restart-dialog.component';
import {
    ConfirmServerStopDialogComponent
} from './components/confirm-server-stop-dialog/confirm-server-stop-dialog.component';
import {
    ConfirmServerSuspendDialogComponent
} from './components/confirm-server-suspend-dialog/confirm-server-suspend-dialog.component';
import {
    ConfirmServerStartDialogComponent
} from './components/confirm-server-start-dialog/confirm-server-start-dialog.component';
import {
    ConfirmServerUnsuspendDialogComponent
} from './components/confirm-server-unsuspend-dialog/confirm-server-unsuspend-dialog.component';
import {
    ReinstallServerConfirmDialogComponent
} from './components/reinstall-server-confirm-dialog/reinstall-server-confirm-dialog.component';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import { UsageStat } from '../../../models/usageStat';
import { JsonApiQueryData } from 'mpugach-angular2-jsonapi';
import { DaySelectDialogComponent } from './day-select-dialog/day-select-dialog.component';
import { environment } from '../../../../environments/environment';
import moment from 'moment';
import { EditServerDialogComponent } from './components/edit-server-dialog/edit-server-dialog.component';
import {
    ConfirmServerRescueDialogComponent
} from './components/confirm-server-rescue-dialog/confirm-server-rescue-dialog.component';
import { HttpHeaders } from '@angular/common/http';

class Interaction {
    createdAt: Date;
    action: string;
    user: User;
}

@Component({
    selector: 'app-server-detail',
    templateUrl: './server-detail.component.html',
    styleUrls: ['./server-detail.component.scss'],
    standalone: false
})
export class ServerDetailComponent implements OnInit {
    public server: Server;
    public interactions: Interaction[] = [];
    public displayedColumns: string[] = ['address', 'type'];

    @ViewChild('performanceChart')
    public performanceChartElement: ElementRef;

    private performanceChart: Chart<'bar' | 'line' | 'scatter' | 'bubble' | 'pie' | 'doughnut' | 'polarArea' | 'radar', number[], string>;
    private usageStats: UsageStat[] = [];
    private selectedTrafficStatsPeriod: { from: Date; to: Date };
    private serverId: number;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly datastore: Datastore,
        private readonly authService: AuthService,
        private readonly dialog: MatDialog,
    ) {
        this.route.params.subscribe((params: Params) => {
            this.serverId = params.id;
            this.refresh();
        });
        Chart.register(...registerables);
    }

    public ngOnInit(): void {
        this.interactions = [
            {
                createdAt: new Date(),
                user: this.authService.getCurrentUser(),
                action: 'restarted'
            },
            {
                createdAt: new Date(),
                user: this.authService.getCurrentUser(),
                action: 'change root password'
            },
            {
                createdAt: new Date(),
                user: this.authService.getCurrentUser(),
                action: 'restored from backup'
            },
            {
                createdAt: new Date(),
                user: this.authService.getCurrentUser(),
                action: 'loaded default preferences'
            },
            {
                createdAt: new Date(),
                user: this.authService.getCurrentUser(),
                action: 'created'
            }
        ];
    }

    public loadTrafficGraph(): void {
        if (!this.server?.getFeature('traffic-statistics')?.available) {
            return;
        }

        if (!this.selectedTrafficStatsPeriod) {
            const fromDaysAgo = this.server.getFeature('traffic-statistics').options['fromDaysAgo'];

            this.selectedTrafficStatsPeriod = {
                from: moment().subtract(fromDaysAgo ? fromDaysAgo : 1, 'day').toDate(),
                to: moment().toDate(),
            };
        }

        const keys = Object.keys(this.datastore.datastoreConfig.models);
        const values = Object.values(this.datastore.datastoreConfig.models);

        const key = keys[values.indexOf(Server)];

        this.datastore
            .findAll(UsageStat,
                {
                    from: this.selectedTrafficStatsPeriod.from.toISOString(),
                    to: this.selectedTrafficStatsPeriod.to.toISOString(),
                },
                new HttpHeaders({ Authorization: `Bearer ${this.authService.getToken()}` }),
                environment.apiBaseUrl + '/' + key + '/' + this.serverId + '/traffic-stats'
            ).subscribe(
            (stats: JsonApiQueryData<UsageStat>) => {
                this.usageStats = stats.getModels();
                this.drawUsageGraph();
            }
        );
    }

    public showRescueConfirm(): void {
        this.dialog.open(ConfirmServerRescueDialogComponent, { data: this.server });
    }

    public showRestartConfirm(): void {
        this.dialog.open(ConfirmServerRestartDialogComponent, { data: this.server });
    }

    public showStartConfirm(): void {
        this.dialog.open(ConfirmServerStartDialogComponent, { data: this.server });
    }

    public showStopConfirm(): void {
        this.dialog.open(ConfirmServerStopDialogComponent, { data: this.server });
    }

    public showPauseConfirm(): void {
        this.dialog.open(ConfirmServerSuspendDialogComponent, { data: this.server })
            .afterClosed()
            .subscribe(() => {
                this.refresh();
            });
    }

    public showUnpauseConfirm(): void {
        this.dialog.open(ConfirmServerUnsuspendDialogComponent, { data: this.server })
            .afterClosed()
            .subscribe(() => {
                this.refresh();
            });
    }

    public ShowReinstallConfirm(): void {
        this.dialog.open(ReinstallServerConfirmDialogComponent, { data: this.server });
    }

    public showEditServer(): void {
        this.dialog.open(EditServerDialogComponent, { data: this.server })
            .afterClosed()
            .subscribe(() => {
                this.refresh();
            });
    }

    private refresh(): void {
        this.datastore.findRecord(
            Server,
            String(this.serverId),
            { include: 'datacenter,networks,assignee' },
            new HttpHeaders({ Authorization: `Bearer ${this.authService.getToken()}` })
        ).subscribe({
            next: (server: Server) => {
                this.server = server;
                this.loadTrafficGraph();
            },
            error: this.datastore.showError,
        });
    }

    private drawUsageGraph(): void {
        if (!this.performanceChart) {
            this.performanceChart = new Chart(this.performanceChartElement.nativeElement.getContext('2d'), {
                type: 'bar',
                data: {
                    labels: this.getChartLabels(),
                    datasets: this.getChartData(),
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                            type: 'time',
                            ticks: {
                                source: 'data',
                            },
                        },
                        y: {
                            stacked: true,
                            beginAtZero: true,
                            display: true,
                            title: {
                                display: true,
                                text: 'Usage (GB)'
                            },
                        }
                    }
                }
            });
            this.performanceChart.render();
            return;
        }

        this.performanceChart.data.datasets = this.getChartData();
        this.performanceChart.data.labels = this.getChartLabels();
        this.performanceChart.update();
    }

    public setTrafficStatsDuration(date: string): void {
        const now = moment();
        let from = moment();

        switch (date) {
            case 'today':
                from.subtract('24', 'hours')
                break;

            case 'week':
                from.subtract('7', 'days')
                break;

            case 'month':
                from.subtract('30', 'days')
                break;

            case 'year':
                from.subtract('1', 'year')
                break;
        }

        this.selectedTrafficStatsPeriod = {
            from: from.toDate(),
            to: now.toDate(),
        };

        this.loadTrafficGraph();
    }

    public setTrafficStatsPeriod(date: string): void {
        const now = new Date();

        switch (date) {
            case 'today':
                this.selectedTrafficStatsPeriod = {
                    from: new Date(now.setHours(0, 0, 0, 0)),
                    to: new Date(now.setHours(23, 59, 59, 999)),
                };
                break;

            case 'yesterday':
                let yesterday = new Date(now.setDate(now.getDate() - 1));

                this.selectedTrafficStatsPeriod = {
                    from: new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()),
                    to: new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59, 999),
                };
                break;

            case 'month':
                this.selectedTrafficStatsPeriod = {
                    from: new Date(now.getFullYear(), now.getMonth(), 1),
                    to: new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999),
                };
                break;

            case 'year':
                this.selectedTrafficStatsPeriod = {
                    from: new Date(now.getFullYear(), 0, 1),
                    to: new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999),
                };
                break;
        }

        this.loadTrafficGraph();
    }

    private getChartData(): { label: string, data: number[], borderColor: string, backgroundColor: string }[] {
        return [
            {
                label: 'In',
                data: this.usageStats.map(e => e.in),
                borderColor: 'red',
                backgroundColor: 'rgba(255, 0, 0, 0.5)',
            },
            {
                label: 'Out',
                data: this.usageStats.map(e => e.out),
                borderColor: 'green',
                backgroundColor: 'rgba(0, 255, 0, 0.5)',
            },
        ];
    }

    private getChartLabels(): string[] {
        return this.usageStats.map((element: UsageStat) => {
            return element.id;
        });
    }

    public customTrafficStatsPeriod(): void {
        const dialogRef = this.dialog.open(DaySelectDialogComponent, {
            data: this.selectedTrafficStatsPeriod
        });

        dialogRef.afterClosed().subscribe((result: { to: Date, from: Date }) => {
            this.selectedTrafficStatsPeriod = {
                to: result.to,
                from: result.from,
            }
            this.loadTrafficGraph();
        });
    }

    public openWhmcs(target: string): void {
        window.open(target, '_blank');
    }

    public getReadablePriceFrequency(frequency) {
        return frequency ? moment.duration(frequency, 'months').humanize() : '-';
    }
}
