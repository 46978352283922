<h2 mat-dialog-title>Rescue Server</h2>
<mat-dialog-content>
    The server is rebooting into rescue mode.
    <br/>
    You are able to use the following credentials to connect via SSH:
    <mat-list>
        <mat-list-item>Username: {{ data['username'] }}</mat-list-item>
        <mat-list-item>Password: {{ data['password'] }}</mat-list-item>
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="onClose()">Close</button>
</mat-dialog-actions>
