<h1 mat-dialog-title>Start Server</h1>
<mat-dialog-content [formGroup]="form">
    Are you sure you would like to start this server?
    <br/>
    <code class="code w-100">{{ this.server.productType }}</code>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="primary" (click)="submit()" [disabled]="!this.form.valid">Start</button>
</mat-dialog-actions>
