import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Server } from '../../../../../models/server';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JsonApiErrorResponse } from '../../../../../services/json-api-error-response';
import { Datastore } from '../../../../../services/datastore';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-confirm-server-suspend-dialog',
    templateUrl: './confirm-server-suspend-dialog.component.html',
    styleUrls: ['./confirm-server-suspend-dialog.component.scss'],
    standalone: false
})
export class ConfirmServerSuspendDialogComponent {

    public form: UntypedFormGroup;

    constructor(
        public dialogRef: MatDialogRef<ConfirmServerSuspendDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public server: Server,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly snackbar: MatSnackBar,
        private readonly http: HttpClient,
        private readonly datastore: Datastore,
    ) {
        this.form = this.formBuilder.group({});
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        if (!this.form.valid) {
            return;
        }

        this.http.patch('/api/v1/servers/' + this.server.id + '/pause', {}, {
            headers: {
                'Content-Type': 'application/vnd.api+json',
            }
        }).subscribe({
            next: () => {
                this.snackbar.open('The server will be suspended shortly');
                this.dialogRef.close();
            },
            error: (error: JsonApiErrorResponse) => this.datastore.showError(error)
        });
    }
}
