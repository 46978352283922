import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Server } from '../../../../../models/server';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { JsonApiErrorResponse } from '../../../../../services/json-api-error-response';
import { Datastore } from '../../../../../services/datastore';

@Component({
    selector: 'app-confirm-server-restart-dialog',
    templateUrl: './confirm-server-restart-dialog.component.html',
    styleUrls: ['./confirm-server-restart-dialog.component.scss'],
    standalone: false
})
export class ConfirmServerRestartDialogComponent {
    public form: UntypedFormGroup;

    constructor(
        public dialogRef: MatDialogRef<ConfirmServerRestartDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public server: Server,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly snackbar: MatSnackBar,
        private readonly http: HttpClient,
        private readonly datastore: Datastore,
    ) {

        this.form = this.formBuilder.group({
            rebootOption: ['', [Validators.required]],
        });
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        if (!this.form.valid) {
            return;
        }

        this.http.patch('/api/v1/servers/' + this.server.id + '/reboot?type=' + this.form.controls.rebootOption.value, {}, {
            headers: {
                'Content-Type': 'application/vnd.api+json',
            }
        }).subscribe({
            next: () => {
                this.snackbar.open('The server will be restarted shortly');
                this.dialogRef.close();
            },
            error: (error: JsonApiErrorResponse) => this.datastore.showError(error)
        });
    }

    public getRestartOptions(): string[] {
        return this.server.getFeature('restart').options as string[];
    }
}
