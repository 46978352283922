import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-day-select-dialog',
    templateUrl: './day-select-dialog.component.html',
    styleUrls: ['./day-select-dialog.component.scss'],
    standalone: false
})
export class DaySelectDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DaySelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        this.dialogRef.close(
            {
                from: this.data.from,
                to: this.data.to,
            }
        );
    }
}
