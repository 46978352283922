import { Attribute, JsonApiModel, JsonApiModelConfig } from 'mpugach-angular2-jsonapi';

@JsonApiModelConfig({
    type: 'usage-stat'
})
export class UsageStat extends JsonApiModel {
    @Attribute()
    in: number;

    @Attribute()
    out: number;

    @Attribute()
    sum: number;
}
