import { Pipe, PipeTransform } from '@angular/core';
import { ContractTermEnum } from '../../enums/contract-term.enum';

@Pipe({
    name: 'contractTerm',
    standalone: false
})
export class ContractTermPipe implements PipeTransform {

    public transform(value: number | null, ...args: unknown[]): string {
        if (typeof value !== 'string') {
            switch (value) {
                case ContractTermEnum.annual:
                    return 'Annual';
                case ContractTermEnum.bi_annual:
                    return 'Bi annual';
                case ContractTermEnum.quarter:
                    return 'Quarter';
                case ContractTermEnum.month:
                    return 'Month';
            }
        }

        return '-';
    }

}
