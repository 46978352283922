<h2 mat-dialog-title>Re-install Server</h2>
<mat-dialog-content [formGroup]="form">
    Are you sure you would like to re-install this server?
    <br/>
    <mat-form-field>
        <mat-label>Operating system</mat-label>
        <mat-select formControlName="image">
            <mat-option *ngFor="let image of (this.server.getFeature('reinstall').options['images'] | keyvalue)"
                        [value]="image.key">{{ image.value }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="warn" (click)="submit()" [disabled]="!this.form.valid">Re-install</button>
</mat-dialog-actions>
