import { Component, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Server } from '../../../../../models/server';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-edit-server-dialog',
    templateUrl: './edit-server-dialog.component.html',
    styleUrls: ['./edit-server-dialog.component.scss'],
    standalone: false
})
export class EditServerDialogComponent {

    public form: UntypedFormGroup;
    public importedSpecs: boolean;

    constructor(
        public dialogRef: MatDialogRef<EditServerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public server: Server,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly snackbar: MatSnackBar,
        private readonly http: HttpClient,
    ) {
        this.importedSpecs = server.provider !== 'Hetzner';

        this.form = this.formBuilder.group({
            name: [server.name, []],
            purpose: [server.purpose, [Validators.required]],
        });

        if (!this.importedSpecs) {
            this.form.addControl('specs', this.formBuilder.group({
                cpu: this.formBuilder.group({
                    quantity: [this.server.specs?.cpu?.quantity ?? 1, [Validators.required]],
                    name: [this.server.specs?.cpu?.name, [Validators.required]],
                }),
                memory: this.formBuilder.group({
                    size: [this.server.specs?.memory?.size, [Validators.required]],
                    unit: ['gb', [Validators.required]], // No input is available for this, just hardcoded to `gb`.
                }),
                network: this.formBuilder.group({
                    quantity: [this.server.specs?.network?.quantity ?? 1, [Validators.required]],
                    speed: [this.server.specs?.network?.speed ?? '1g', [Validators.required]],
                }),
                disks: this.formBuilder.array([]),
            }));

            this.server.specs?.disks?.forEach((disk) => {
                this.disks.push(this.formBuilder.group({
                    quantity: [disk.quantity, [Validators.required]],
                    unit: [disk.unit, [Validators.required]],
                    size: [disk.size, [Validators.required]],
                    type: [disk.type, [Validators.required]]
                }));
            });

            if ((this.server.specs?.disks?.length || 0) == 0) {
                this.addDisk()
            }
        }
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        if (!this.form.valid) {
            return;
        }

        this.http.patch('/api/v1/servers/' + this.server.id, {
            data: {
                type: 'servers',
                id: this.server.id,
                attributes: this.form.value,
            },
        }, {
            headers: {
                'Content-Type': 'application/vnd.api+json',
            }
        }).subscribe(() => {
            this.snackbar.open('The server is updated.');
            this.dialogRef.close();
        });
    }

    addDisk() {
        const diskForm = this.formBuilder.group({
            quantity: [1, [Validators.required]],
            unit: ['', [Validators.required]],
            size: ['', [Validators.required]],
            type: ['', [Validators.required]]
        });

        this.disks.push(diskForm);
    }

    removeDisk(diskId: number) {
        this.disks.removeAt(diskId);
    }

    get specs() {
        return this.form.controls.specs as UntypedFormGroup;
    }

    get disks() {
        return this.specs.controls.disks as UntypedFormArray;
    }
}
