import { AfterViewInit, Component, inject, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutConfirmDialogComponent } from '../toolbar/logout-confirm-dialog/logout-confirm-dialog.component';
import { AuthService } from '../../../services/auth.service';
import { fromEvent, Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { LoaderService } from '../../../services/loader.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    standalone: false
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    protected readonly isMobile = signal(true);

    private readonly _mobileQuery: MediaQueryList;
    private readonly _mobileQueryListener: () => void;

    constructor(
        private readonly dialog: MatDialog,
        public readonly authService: AuthService,
        private readonly loaderService: LoaderService,
    ) {
        const media = inject(MediaMatcher);

        this._mobileQuery = media.matchMedia('(max-width: 600px)');
        this.isMobile.set(this._mobileQuery.matches);
        this._mobileQueryListener = () => this.isMobile.set(this._mobileQuery.matches);
        this._mobileQuery.addEventListener('change', this._mobileQueryListener);
    }

    public static displayThreshold = 750;
    public resizeObservable$: Observable<Event>;
    public menuIsOpen = false;

    @ViewChild('sidenav') sidenav: MatSidenav;

    public ngOnInit(): void {
        this.menuIsOpen = window.innerWidth > LayoutComponent.displayThreshold;

        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeObservable$.subscribe((evt: Event) => {
            // @ts-ignore
            this.menuIsOpen = evt.currentTarget.innerWidth > LayoutComponent.displayThreshold;
            this.sidenav.mode = this.menuIsOpen ? 'side' : 'over';
        });

        this.loaderService.show();
    }

    ngOnDestroy(): void {
        this._mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }

    public ngAfterViewInit(): void {
        this.loaderService.hide();
    }

    public logoutHandle(): void {
        this.dialog.open(LogoutConfirmDialogComponent, {
            width: '250px'
        });
    }
}
