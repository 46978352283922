<div class="container">
    <div class="row">
        <div class="col col-lg-4">
            <mat-card appearance="outlined" class="server-info">
                <mat-card-header>
                    <mat-card-title>{{ this.server?.displayName.length > 0 ? this.server?.displayName : 'No name' }}</mat-card-title>
                    <mat-card-subtitle>{{ (this.server?.productType) ?? '-' }} at {{ this.server?.provider | serverType }}
                    </mat-card-subtitle>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="showEditServer()">Edit</button>
                    </mat-menu>
                </mat-card-header>
                <mat-card-content>
                    <table>
                        <tbody>
                        <tr *ngIf="this.server?.name">
                            <th>Name</th>
                            <td>{{ this.server?.name ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Purpose</th>
                            <td>{{ this.server?.purpose ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>{{ this.server?.status | titlecase }}</td>
                        </tr>
                        <tr>
                            <th>Created at</th>
                            <td>{{ this.server?.createdAt | date:'medium' }}</td>
                        </tr>
                        <tr>
                            <th>Updated at</th>
                            <td>{{ this.server?.updatedAt | date:'medium' }}</td>
                        </tr>
                        <tr>
                            <th>Product</th>
                            <td>{{ this.server?.productType ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Datacenter</th>
                            <td>{{ this.server?.datacenter?.name ?? '-' }}</td>
                        </tr>
                        <ng-container *ngIf="this.server?.specs">
                            <tr>
                                <th>CPU</th>
                                <td>{{ this.server?.specs?.cpu?.quantity }}x {{ this.server?.specs?.cpu?.name }}</td>
                            </tr>
                            <tr>
                                <th>Memory</th>
                                <td>{{ this.server?.specs?.memory?.size }} {{ this.server?.specs?.memory?.unit | uppercase }}</td>
                            </tr>
                            <tr>
                                <th>Network</th>
                                <td>{{ this.server?.specs?.network?.quantity }}x {{ this.server?.specs?.network?.speed }}</td>
                            </tr>
                            <tr>
                                <th>Disks</th>
                                <td>
                                    <ng-container *ngFor="let disk of this.server?.specs?.disks; let isLast = last">
                                        {{ disk.quantity }}
                                        x {{ disk.type | uppercase }} {{ disk.size }} {{ disk.unit | uppercase }}{{ isLast ? '' : ', ' }}
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </mat-card-content>
                <mat-card-actions *ngIf="this.server?.hasFeatures()">
                    <button mat-button color="primary" (click)="showRescueConfirm()"
                            *ngIf="this.server?.getFeature('rescue')?.available">
                        Rescue
                    </button>
                    <button mat-button color="primary" (click)="showRestartConfirm()"
                            *ngIf="this.server?.getFeature('restart')?.available">
                        Restart
                    </button>
                    <button mat-button color="primary" (click)="showStartConfirm()"
                            *ngIf="this.server?.getFeature('start')?.available">
                        Start
                    </button>
                    <button mat-button color="warn" (click)="showStopConfirm()"
                            *ngIf="this.server?.getFeature('stop')?.available">
                        Stop
                    </button>
                    <button mat-button color="warn" (click)="showPauseConfirm()"
                            *ngIf="this.server?.getFeature('pause')?.available">
                        Suspend
                    </button>
                    <button mat-button color="warn" (click)="showUnpauseConfirm()"
                            *ngIf="this.server?.getFeature('unpause')?.available">
                        Unsuspend
                    </button>
                    <button mat-button color="warn" (click)="ShowReinstallConfirm()"
                            *ngIf="this.server?.getFeature('reinstall')?.available">
                        Re-install
                    </button>
                </mat-card-actions>
            </mat-card>

            <mat-card appearance="outlined" class="provider">
                <mat-card-header>
                    <mat-card-title>Provider</mat-card-title>
                    <mat-card-subtitle>{{ this.server?.provider | serverType }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <table>
                        <tbody>
                        <tr>
                            <th>ID at {{ this.server?.provider | serverType }}</th>
                            <td>{{ this.server?.providerId }}</td>
                        </tr>
                        <tr>
                            <th *ngIf="this.server?.providerName">Name</th>
                            <td *ngIf="this.server?.providerName">{{ this.server?.providerName }}</td>
                        </tr>
                        <tr>
                            <th>Provider</th>
                            <td>{{ this.server?.provider | serverType }}</td>
                        </tr>
                        <tr>
                            <th>Contract expiry</th>
                            <td>{{ (this.server?.contractExpireAt | date:'mediumDate') ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Price</th>
                            <td>{{ (this.server?.providerPrice | currency:'EUR') ?? '-' }} per {{ getReadablePriceFrequency(this.server?.providerPriceFrequency) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="whmcs">
                <mat-card-header>
                    <mat-card-title>WHMCS</mat-card-title>
                    <mat-card-subtitle *ngIf="this.server?.assignee">Assigned to {{ this.server?.assignee?.name }}
                    </mat-card-subtitle>
                    <mat-card-subtitle *ngIf="!this.server?.assignee">Unassigned</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content *ngIf="!this.server?.assignee">
                    <span>This server is currently not assigned to any service in WHMCS.</span>
                </mat-card-content>
                <mat-card-content class="grid" *ngIf="this.server?.assignee">
                    <table>
                        <tbody>
                        <tr>
                            <th>Service ID</th>
                            <td>{{ this.server?.whmcsId }}</td>
                        </tr>
                        <tr>
                            <th>Client ID</th>
                            <td>{{ this.server?.assignee?.whmcsId }}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{{ this.server?.assignee?.name }}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{{ this.server?.assignee?.email }}</td>
                        </tr>
                        <tr>
                            <th>Price</th>
                            <td>{{ (this.server?.whmcsPrice | currency:'EUR') ?? '-' }} per {{ getReadablePriceFrequency(this.server?.whmcsPriceFrequency) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </mat-card-content>
                <mat-card-actions *ngIf="this.server?.assignee">
                    <button mat-button color="primary" (click)="openWhmcs(this.server?.whmcsUrl)"
                            *ngIf="this.server?.whmcsUrl">Open service in WHMCS
                    </button>
                    <button mat-button color="primary" (click)="openWhmcs(this.server?.assignee?.whmcsUrl)"
                            *ngIf="this.server?.assignee?.whmcsUrl">Open client in WHMCS
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>

        <div class="col col-lg-8">
            <mat-card appearance="outlined" class="graph" *ngIf="this.server?.getFeature('traffic-statistics')?.available">
                <mat-card-header>
                    <mat-card-title>Traffic usage</mat-card-title>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="setTrafficStatsDuration('today')">Last 24 hours</button>
                        <button mat-menu-item (click)="setTrafficStatsDuration('week')">Last 7 days</button>
                        <button mat-menu-item (click)="setTrafficStatsDuration('month')">Last 30 days</button>
                        <button mat-menu-item (click)="setTrafficStatsDuration('year');">Last 1 year</button>
                        <button mat-menu-item (click)="setTrafficStatsPeriod('today')">Today</button>
                        <button mat-menu-item (click)="setTrafficStatsPeriod('yesterday')">Yesterday</button>
                        <button mat-menu-item (click)="setTrafficStatsPeriod('month')">This Month</button>
                        <button mat-menu-item (click)="setTrafficStatsPeriod('year');">This Year</button>
                        <button mat-menu-item (click)="customTrafficStatsPeriod()">Custom</button>
                    </mat-menu>
                </mat-card-header>
                <mat-card-content class="chart-container">
                    <canvas #performanceChart class="chart"></canvas>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="network">
                <mat-card-header>
                    <mat-card-title>Network</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="server?.networks" class="w-100" aria-describedby="Networks">
                        <caption>Network</caption>
                        <ng-container matColumnDef="address">
                            <th mat-header-cell *matHeaderCellDef>Address</th>
                            <td mat-cell *matCellDef="let element">{{ element.address }}{{ element.mask ? '/' + element.mask : '' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef>Type</th>
                            <td mat-cell *matCellDef="let element"> {{ element.networkType | titlecase }}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
