<h2 mat-dialog-title>Restart Server</h2>
<mat-dialog-content [formGroup]="form">
    Are you sure you would like to restart this server?
    <br/>
    Please select a reboot option and type the product name.
    <mat-form-field>
        <mat-label>Reboot method</mat-label>
        <mat-select formControlName="rebootOption" required>
            <mat-option *ngFor="let rebootMethod of this.getRestartOptions() | keyvalue" [value]="rebootMethod.key">
                {{ rebootMethod.value }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="accent" (click)="submit()" [disabled]="!this.form.valid">Restart</button>
</mat-dialog-actions>
