import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { LayoutComponent } from './components/layout/layout.component';
import { MainComponent } from './components/main/main.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
    LogoutConfirmDialogComponent
} from './components/toolbar/logout-confirm-dialog/logout-confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ProfileComponent } from './components/profile/profile.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServersComponent } from './components/servers/servers.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ServerDetailComponent } from './components/server-detail/server-detail.component';
import { MatSelectModule } from '@angular/material/select';
import {
    ConfirmServerRestartDialogComponent
} from './components/server-detail/components/confirm-server-restart-dialog/confirm-server-restart-dialog.component';
import {
    ConfirmServerStopDialogComponent
} from './components/server-detail/components/confirm-server-stop-dialog/confirm-server-stop-dialog.component';
import {
    ConfirmServerSuspendDialogComponent
} from './components/server-detail/components/confirm-server-suspend-dialog/confirm-server-suspend-dialog.component';
import {
    ConfirmServerUnsuspendDialogComponent
} from './components/server-detail/components/confirm-server-unsuspend-dialog/confirm-server-unsuspend-dialog.component';
import {
    ConfirmServerStartDialogComponent
} from './components/server-detail/components/confirm-server-start-dialog/confirm-server-start-dialog.component';
import {
    AddServerDialogComponent
} from './components/servers/components/add-server-dialog/add-server-dialog.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import {
    ReinstallServerConfirmDialogComponent
} from './components/server-detail/components/reinstall-server-confirm-dialog/reinstall-server-confirm-dialog.component';
import {
    RemoveServerConfirmDialogComponent
} from './components/server-detail/components/remove-server-confirm-dialog/remove-server-confirm-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { DaySelectDialogComponent } from './components/server-detail/day-select-dialog/day-select-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ServerTypePipe } from './pipes/server-type.pipe';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
    EditServerDialogComponent
} from './components/server-detail/components/edit-server-dialog/edit-server-dialog.component';
import {
    ConfirmServerRescueDialogComponent
} from './components/server-detail/components/confirm-server-rescue-dialog/confirm-server-rescue-dialog.component';
import {
    DetailsServerRescueDialogComponent
} from './components/server-detail/components/details-server-rescue-dialog/details-server-rescue-dialog.component';
import { ContractTermPipe } from './pipes/contract-term.pipe';

@NgModule({
    declarations: [
        LayoutComponent,
        MainComponent,
        LogoutConfirmDialogComponent,
        ProfileComponent,
        ServersComponent,
        ServerDetailComponent,
        ConfirmServerRestartDialogComponent,
        ConfirmServerRescueDialogComponent,
        ConfirmServerStopDialogComponent,
        ConfirmServerSuspendDialogComponent,
        ConfirmServerUnsuspendDialogComponent,
        ConfirmServerStartDialogComponent,
        DetailsServerRescueDialogComponent,
        AddServerDialogComponent,
        ReinstallServerConfirmDialogComponent,
        RemoveServerConfirmDialogComponent,
        EditServerDialogComponent,
        DaySelectDialogComponent,
        ServerTypePipe,
        ContractTermPipe,
    ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatListModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatStepperModule,
        MatRippleModule,
        MatCheckboxModule,
        MatRadioModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSortModule,
        MatAutocompleteModule,
    ]
})
export class DashboardModule {
}
