<h2 mat-dialog-title>Rescue Server</h2>
<mat-dialog-content [formGroup]="form">
    Are you sure you would like to restart the server into rescue?
    <br/>
    The rescue password will be provided after the confirmation.
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="primary" (click)="submit()" [disabled]="!this.form.valid">Restart</button>
</mat-dialog-actions>
