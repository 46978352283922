<h2 mat-dialog-title>Add Server</h2>
<mat-dialog-content [formGroup]="form">
    <form [formGroup]="this.form" *ngIf="this.addSubscription?.closed === null">
        <p>
            Manually adding a server will make sure the server is added right away into SIMP. By default new servers are
            added every two hours automatically.
        </p>
        <mat-radio-group formControlName="provider" required>
            <div class="row">
                <div class="col-3">
                    <mat-radio-button [value]="'Hetzner'">
                        <img src="/assets/images/providers/hetzner.png" class="w-100" alt="Hetzner logo">
                    </mat-radio-button>
                </div>
                <div class="col-3">
                    <mat-radio-button [value]="'Leaseweb'">
                        <img src="/assets/images/providers/leaseweb.png" class="w-100" alt="Leaseweb logo">
                    </mat-radio-button>
                </div>
            </div>
        </mat-radio-group>
        <mat-form-field class="mt-2">
            <mat-label>Identifier</mat-label>
            <input matInput
                   [placeholder]="this.getPlaceholderFormProvider().placeholder"
                   [type]="this.getPlaceholderFormProvider().numeric ? 'number' : 'text'"
                   formControlName="providerId"
                   required>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="actions" *ngIf="this.addSubscription?.closed === null">
    <button mat-button color="accent" (click)="this.onNoClick()">Cancel</button>
    <button mat-button color="primary" (click)="this.submit()">Add</button>
</mat-dialog-actions>
