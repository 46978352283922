<h2 mat-dialog-title>Stop Server</h2>
<mat-dialog-content [formGroup]="form">
    Are you sure you would like to remove this server from inventory?
    <br/>
    <code class="code w-100">{{ this.data.productType }}</code>
    <br/>
    To remove this server, please type the product name.
    <br/>
    <mat-form-field>
        <mat-label>Product name</mat-label>
        <input type="text" matInput formControlName="product">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="accent" (click)="submit()" [disabled]="!this.valid()" [class.disabled]="!this.valid()">
        Remove
    </button>
</mat-dialog-actions>
