<mat-toolbar color="primary">
    <button mat-icon-button class="example-icon" aria-label="menu" (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <span>SIMP</span>
    <span class="toolbar-spacer"></span>
    <button mat-icon-button aria-label="Logout of the application" id="profile-button" routerLink="/profile">
        <img [src]="authService.getCurrentUserAvatar()" alt="profile image">
    </button>
    <button mat-icon-button aria-label="Logout of the application" (click)="logoutHandle()">
        <mat-icon>logout</mat-icon>
    </button>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav [mode]="isMobile() ? 'over' : 'side'"
                 [opened]="menuIsOpen"
                 [fixedInViewport]="isMobile()"
                 fixedTopGap="56" #sidenav>
        <mat-nav-list>
            <mat-list-item routerLink="/dashboard">
                <mat-icon matListItemIcon>home</mat-icon>
                <span matListItemLine>Dashboard</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item role="listitem" routerLink="/servers">
                <mat-icon matListItemIcon>storage</mat-icon>
                <span matListItemLine>Servers</span>
            </mat-list-item>
            <mat-divider></mat-divider>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="container">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
